import React from 'react';
import Layout from 'components/Layout';
import { Container, Heading, OrderSummary } from 'components';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';

function OrdersPage() {
  return (
    <Layout pageTitle="Order Summary">
      <Container>
        <Heading className="text-center mb-20">Quote Summary</Heading>
        <OrderSummary />
      </Container>
    </Layout>
  );
}

export default OrdersPage;
